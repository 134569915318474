import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Heading, Section, Image, Container }  from 'react-bulma-components';
export default class SecondPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      post: {
        node: {
          id: '',
          createdAt: '',
          updatedAt: '',
          title: '',
          subtitle: '',
          headerImage: {
            file: {
              url: ''
            }
          },
          content: {
            childContentfulRichText: {
              html: ''

            }
          }
        }
      }
   
    };
  }

  componentDidMount() {
    const post = this.props.location.state.post;
    this.setState({
      post: post
    })
  }
  
  render() {
    return <Layout>
    <SEO title="Page two" />
    <Section style={{'padding-bottom': '0'}}>
        <div style={{height:'312px', overflow:'hidden'}}>
          <Image src={this.state.post.node.headerImage.file.url}  height="150" />
        </div>
        <Heading size={2}>
          {this.state.post.node.title}
        </Heading> 
        <Heading subtitle>
          {this.state.post.node.subtitle}
        </Heading>
    </Section>
    <Section>
      <Container> 
        <div 
          className="content" 
          dangerouslySetInnerHTML={{__html:this.state.post.node.content.childContentfulRichText.html}}>
        </div>
      </Container>
    </Section>
  </Layout>
  }


}

